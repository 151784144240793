import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 432 432">
    <title>Logo</title>
    <g>
        <path d="M191.51,422.13c-18.89,0-36.94-2.88-51.45-8.79c-41.19-16.79-67.42-46.69-75.84-86.47c-9.73-45.94,4.9-92.28,38.19-120.92
        c15.33-13.19,34.48-22.22,53.93-25.43c0.35-0.06,0.7-0.11,1.05-0.17c-6.24-17.31-12.92-24.98-43.13-56.56
        C75.81,83.62,75.35,22.13,75.35,19.53c0-2.33,1.61-4.35,3.87-4.87c1.05-0.24,26.21-5.95,62.55-4.57
        c33.56,1.27,83.27,9.15,129.48,39.47c51.43,33.75,75.65,79.97,86.92,112.8c13.54,39.48,16.3,82.37,7.56,117.69
        c-7.54,30.5-20.86,57.39-39.58,79.91c-16.14,19.41-36.26,35.27-58.21,45.87C245.72,416.55,217.8,422.13,191.51,422.13z
         M172.44,189.31c-21.99,0-45.09,8.36-63.51,24.22c-30.52,26.26-43.9,68.9-34.93,111.27c7.82,36.91,31.31,63.59,69.83,79.29
        c27.53,11.22,78.86,12.48,119.77-7.26c45.43-21.93,79.12-65.37,92.43-119.17c16.55-66.94-9.45-166.7-90.26-219.73
        C190.69,8.65,104.91,20.16,85.51,23.61c0.81,14.13,5.78,61.72,35.97,93.27c32.4,33.85,39.02,41.69,46.14,62.42
        c16.68-0.85,32.58,2.66,46.51,10.33c17.81,9.81,31.86,29.01,37.58,51.37c5.63,21.96,2.56,44.46-8.41,61.75
        c-11.31,17.82-31.87,27.23-55,25.2c-21.02-1.85-38.62-12.86-44.85-28.03c-6.5-15.83-3.63-36.14,7-49.38
        c8.57-10.68,20.91-15.25,34.74-12.87c18.66,3.22,24.94,17.91,24.64,29.51c-0.34,13.32-8.4,24.3-18.74,25.54
        c-2.74,0.32-5.23-1.63-5.56-4.37c-0.33-2.74,1.63-5.23,4.37-5.56c5.27-0.63,9.72-7.75,9.93-15.87c0.1-3.87-0.79-16.71-16.34-19.4
        c-12.93-2.23-20.86,3.81-25.24,9.27c-8.31,10.37-10.65,26.91-5.55,39.33c4.75,11.58,19.41,20.36,36.48,21.87
        c19.33,1.7,36.41-5.99,45.67-20.59c9.5-14.96,12.11-34.61,7.16-53.91c-5.06-19.73-17.29-36.58-32.72-45.09
        C198.22,192.27,185.52,189.31,172.44,189.31z" fill="currentColor" stroke="currentColor" strokeWidth="10"/>
    </g>
  </svg>
);

export default IconLogo;
